import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Parallax from '../ParallaxAnimation/Parallax.jsx'
import PropTypes from 'prop-types'

const WorkCard = ({ project }) => {
  const { title, url, subTitle, imgs } = project
  return (
    <div className='md:pb-20 pb:10'>
      <Link to={`/${url}`}>
        <Parallax>
          <GatsbyImage
            className='aspect-video rounded-2xl'
            imgClassName='openBtn'
            image={imgs[0]?.childImageSharp?.gatsbyImageData}
            alt={title}
            loading='lazy'
            layout='fullWidth'
          />
        </Parallax>
      </Link>
      <div className='py-6'>
        <p className='mb-2 text-2xl leading-7 lg:text-3xl lg:leading-snug'>
          {title}
        </p>
        <p className='text-lg leading-6 font-normal lg:text-xl lg:leading-7'>
          {subTitle}
        </p>
      </div>
    </div>
  )
}

export default WorkCard

WorkCard.propTypes = {
  project: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    imgs: PropTypes.object
  }).isRequired
}
