import React from 'react'
import Layout from '../components/en/Layout/Layout'
import WorksPage from '../components/en/Works/WorksPage'

const Work = () => (
  <Layout
    title='Our Works | the.good.code; - Custom IT Services Tailored to Your Needs'
    description='Discover the portfolio of IT services we have developed at the.good.code;. From custom software to web development, our works are tailored to meet our clients needs.'
  >
    <WorksPage />
  </Layout>
)

export default Work
