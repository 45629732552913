import React from 'react'
import SectionHeader from '../../common/Header/SectionHeader'
import ContentContainer from '../../common/Layout/ContentContainer'
import WorkCard from '../../common/Works/WorkCard'
import FadeInUpAnimation from '../../common/FadeInUpAnimation/FadeInUpAnimation'
import { graphql, useStaticQuery } from 'gatsby'
import { map, sortBy } from 'lodash'

const query = graphql`
  query {
    allFile(
      filter: {
        sourceInstanceName: { eq: "content" }
        relativeDirectory: { eq: "works" }
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              id
              url
              title
              subTitle
              webSite
              locale
              imgs {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`
const WorksPage = () => {
  const {
    allFile: { edges }
  } = useStaticQuery(query)

  const projects = map(
    edges.filter(
      (elem) => elem.node.childMarkdownRemark.frontmatter.locale === 'en'
    ),
    'node.childMarkdownRemark.frontmatter'
  )
  const sortedProjects = sortBy(projects, (project) => project.id)

  return (
    <section>
      <SectionHeader wordsToType={['Work']} />
      <ContentContainer>
        <section className='article-padding'>
          <div className='grid md:grid-cols-2 gap-5'>
            {sortedProjects.map((project, index) => (
              <FadeInUpAnimation
                key={index}
                delay={index / 4 > 0.75 ? 0 : index / 4}
              >
                <WorkCard project={project} />
              </FadeInUpAnimation>
            ))}
          </div>
        </section>
      </ContentContainer>
    </section>
  )
}

export default WorksPage
